<template>
  <div
      class="bg-white border-t py-3 border-cool-gray-200 dark:bg-gray-800 dark:border-cool-gray-50 bottom-0 min-w-full">
    <div class="flex items-center justify-between px-6 mx-auto">
      <div class="flex flex-1 text-sm font-normal text-gray-500 transition-colors duration-150 dark:text-gray-100">
        Propulsé par OCV 1.0.0 &copy; {{ year }} Groupe Dadoun
      </div>
      <div class="flex items-center flex-shrink-0">
        <div class="text-center md:flex">
          <a href="#"
             class="relative inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Besoin
            d'aide ?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const year = new Date().getFullYear()
</script>
